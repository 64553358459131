<template>
  <section>
    <JokeFilter />
    <Preloader v-if="callingAPI" />
    <JokeTable
      :jokes="jokes"
      :totalCount="totalCount"
      :current-page="page"
      :on-page-change="setPageAndGetRecords"
    />
  </section>
</template>

<script>
import { mapState } from 'vuex'
import Preloader from '@/components/preloader/Preloader'
import JokeFilter from '@/components/filter/FilterJoke'
import JokeTable from '@/components/table/JokeTable'

export default {
  name: 'JokeListView',
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    jokes () {
      return this.$store.getters['joke/list']
    },
    totalCount () {
      return this.$store.getters['joke/totalCount']
    },
    page () {
      return this.$store.getters['joke/page']
    }
  },
  components: {
    JokeTable,
    Preloader,
    JokeFilter
  },
  methods: {
    getJokes () {
      this.$store.dispatch('joke/fetch')
        .then(() => {
          this.dataLoaded = true
        })
    },
    setPageAndGetRecords (page) {
      this.$store.commit('joke/setPage', page)
      this.getJokes()
    }
  },
  mounted () {
    this.getJokes()
  }
}
</script>
