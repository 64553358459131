<template>
  <div class="abstract-data-table__td">
    <span v-if="header" class="abstract-data-table__td-desc">{{ header }}</span>
    <template v-for="(block, index) in blocks">
      <span
        class="abstract-data-table__td-title"
        :key="block"
      >
        {{ block }}<template v-if="index < (blocks.length - 1)">,</template>
      </span>
    </template>
  </div>
</template>

<script>
export default {
  name: 'BlockArrayColumn',
  props: {
    blocks: {
      type: Array,
      required: true
    },
    header: {
      type: String,
      required: true
    }
  }
}
</script>
