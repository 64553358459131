<template>
  <div class="abstract-data-table__td">
    <span v-if="header" class="abstract-data-table__td-desc">{{ header }}</span>
    <span v-if="voting.enabled">
      <span class="abstract-data-table__td-voting abstract-data-table__td-voting--likes">
        <i class="fa fa-thumbs-up"/>
        {{ voting.likes }}
      </span>
      <span class="abstract-data-table__td-voting abstract-data-table__td-voting--dislikes">
        <i class="fa fa-thumbs-down"/>
        {{ voting.dislikes }}
      </span>
    </span>
    <span v-else>
      <i class="fas fa-times" />
    </span>
  </div>
</template>

<script>
export default {
  name: 'VotingColumn',
  props: {
    header: {
      type: String,
      required: true
    },
    voting: {
      type: Object,
      required: true
    }
  }
}
</script>
<style scoped lang="scss">
  .abstract-data-table {
    &__td-voting {
      color: #ffffff;
      padding: rem(3px) rem(10px);
      margin-right: rem(5px);
      border-radius: rem(4px);
      font-size: 75%;
      &--likes {
        background-color: #26c6da;
      }
      &--dislikes {
         background-color: #fc4b6c;
      }
    }
  }
</style>
